import { extendTheme } from '@chakra-ui/react';
import Input from './input';
import Link from './link';
import Select from './select';
import Tabs from './tabs';
import Badge from './badge';

export const theme = extendTheme({
  colors: {
    primary: {
      blue: '#0067D8',
      darkblue: '#0052ad',
      black: '#333333',
      gray: '#979797',
    },
  },
  sizes: {
    container: {
      xl: '1440px',
    },
  },
  spacing: {
    space: {
      13: '3.25rem',
    },
  },
  space: {
    15: '3.75rem',
  },
  components: {
    Input,
    Link,
    Select,
    Tabs,
    Badge,
  },
});
