import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const Tabs = defineMultiStyleConfig({
  variants: {
    badge: definePartsStyle((props) => {
      const { colorScheme: c } = props;
      return {
        tab: {
          bg: '#f3f4f9',
          py: 2,
          px: { base: 2, md: 4 },
          mr: '10px',
          h: '40px',
          fontWeight: 'bold',
          borderRadius: '20px',
          _selected: {
            bg: 'primary.blue',
            color: 'white',
          },
        },
        tabpanels: {
          py: 4,
        },
        tabpanel: {
          p: 0,
        },
      };
    }),
  },
});

export default Tabs;
