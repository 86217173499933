import Router from 'next/router';
import { useState, useEffect } from 'react';
import { Spinner, Flex, Stack } from '@chakra-ui/react';

function Loading() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const start = () => {
      setLoading(true);
    };
    const end = () => {
      setTimeout(() => setLoading(false), 500);
    };
    Router.events.on('routeChangeStart', start);
    Router.events.on('routeChangeComplete', end);
    Router.events.on('routeChangeError', end);
    return () => {
      Router.events.off('routeChangeStart', start);
      Router.events.off('routeChangeComplete', end);
      Router.events.off('routeChangeError', end);
    };
  }, []);

  return (
    <Flex
      pointerEvents={loading ? 'visible' : 'none'}
      zIndex='10000'
      justify='center'
      align='center'
      pos='fixed'
      left='0'
      top={{ base: '50px', md: '72px' }}
      w='100vw'
      h={{ base: 'calc(100vh - 50px)', md: 'calc(100vh - 72px)' }}
      bgColor='#fff'
      opacity={loading ? 1 : 0}
      transition={!loading ? '0.5s ease' : ''}
    >
      <Stack
        cursor='pointer'
        spacing={'12px'}
        align='center'
        justify='center'
        w='50%'
        maxW='150px'
      >
        <Spinner w='30px' h='30px' speed='0.3s' mb='10px' />
      </Stack>
    </Flex>
  );
}

export default Loading;
