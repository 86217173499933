import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const Badge = defineStyleConfig({
  variants: {
    bullet: defineStyle({
      px: '8px',
      py: '2px',
      h: '26px',
      borderRadius: '26px',
      fontSize: '14px',
      display: 'inline-flex',
      alignItems: 'center',
    }),
  },
});

export default Badge;
