import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

export const Input = defineMultiStyleConfig({
  sizes: {
    sm: definePartsStyle({
      field: {
        height: '36px',
        fontSize: '14px',
        px: '4',
        py: '2',
      },
      addon: {
        height: '36px',
        fontSize: '14px',
        px: '4',
        py: '2',
      },
    }),
    md: definePartsStyle({
      field: {
        height: '52px',
        fontSize: '14px',
        px: '5',
        py: '4',
      },
      addon: {
        height: '52px',
        fontSize: '14px',
        px: '5',
        py: '4',
      },
    }),
  },
});

export default Input;
