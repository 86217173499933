import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

export const Select = defineMultiStyleConfig({
  sm: definePartsStyle({
    field: {
      height: '36px',
      fontSize: '14px',
      px: '4',
      py: '2',
    },
  }),
});

export default Select;
