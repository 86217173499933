import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const Link = defineStyleConfig({
  baseStyle: defineStyle(({ colorSchme: c }) => ({
    color: 'primary.black',
    fontSize: 'lg',

    _hover: {
      color: 'primary.blue',
      textDecoration: 'none',
    },
  })),

  variants: {
    expansion: defineStyle(({ colorScheme: c }) => ({
      position: 'relative',
      color: '#9195a4',
      fontSize: 'sm',

      _after: {
        position: 'absolute',
        content: '" "',
        display: 'block',
        width: '0',
        height: '2px',
        backgroundColor: 'primary.blue',
        transition: '0.2s all ease',
        left: '50%',
        bottom: '-4px',
      },
      _hover: {
        _after: {
          left: 0,
          width: '100%',
        },
      },
    })),
  },
});

export default Link;
